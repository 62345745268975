import React from 'react';
import styles from './Splash.module.scss';

const Splash = ({ children }) => {
    return (
        <div className={styles.splash}>
            {children}
        </div>
    );
};

export default Splash;