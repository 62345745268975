import React from 'react';
import { Link } from 'gatsby';
import styles from './ContactItem.module.scss';

const ContactItem = ({ to, href, image }) => {
    const LinkTag = to ? Link : 'a';
    const ImageTag = image;
    return (
        <LinkTag to={to} href={href}>
            <div className={styles.item}>
                <ImageTag />
            </div>
        </LinkTag>
    );
};

export default ContactItem;