import React from 'react';
import CloseButton from './CloseButton';
import styles from './Modal.module.scss';

export default class Modal extends React.Component {
    constructor (props) {
        super(props);
    }

    render () {
        const { isVisible, title, onClose, onClickOutside, children } = this.props;

        if (!isVisible) {
            return null;
        }

        return (
            <div className={styles.wrapper} onClick={onClickOutside}>
                <div className={styles.container} onClick={e => e.stopPropagation()}>
                    <div className={styles.header}>
                        <h2>{title}</h2>
                    </div>
                    <div className={styles.content}>
                        {children}
                    </div>
                    <CloseButton onClick={onClose} />
                </div>
            </div>
        );
    }
}