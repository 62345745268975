import React from 'react';
import { Location } from '@reach/router';
import NavBar from '../../components/navigation/NavBar';
import NavItem from '../../components/navigation/NavItem';

class Nav extends React.Component {
    constructor (props) {
        super(props);
    }

    getNavigationItems = () => {
        const isActive = (path) => {
            if (path === '/') {
                return this.props.location.pathname === '/';
            } else {
                return this.props.location.pathname.includes(path);
            }
        }
        return (
            <React.Fragment>
                <NavItem to='/' isActive={isActive('/')}>Startseite</NavItem>
                <NavItem to='/leistungen' isActive={isActive('/leistungen')}>Leistungen</NavItem>
                <NavItem to='/kontakt' isActive={isActive('/kontakt')}>Kontakt</NavItem>
            </React.Fragment>
        );
    }

    render () {
        return (
            <NavBar>
                {this.getNavigationItems()}
            </NavBar>
        );
    }
}

export default props => (
    <Location>
        {locationProps => <Nav {...locationProps} {...props} />}
    </Location>
);