import React from 'react';
import { Helmet } from 'react-helmet';
import { useStaticQuery, graphql } from 'gatsby';
import bg from '../../assets/images/bg.jpg';

const SEO = ({ title, description, url }) => {
    const {
        site: {
            siteMetadata: { siteUrl, defaultTitle, defaultDescription, titleTemplate }
        }
    } = useStaticQuery(graphql`
        query SEO {
            site {
            siteMetadata {
                defaultTitle: title
                titleTemplate
                defaultDescription: description
                siteUrl: url
            }
            }
        }
    `);
    const seo = {
        title: title || defaultTitle,
        description: description || defaultDescription,
        url: (url && url.startsWith('/') ? siteUrl + url : url) || siteUrl,
        image: bg,
    };
    const templatedTitle = titleTemplate.replace('%s', seo.title);

    return (
        <Helmet title={seo.title} titleTemplate={titleTemplate} htmlAttributes={{ lang: 'en' }}>
            <meta name='description' content={seo.description} />
            <meta name='image' content={seo.image} />
            {seo.url && <meta property='og:url' content={seo.url} />}
            <meta property='og:type' content='website' />
            {seo.title && <meta property='og:title' content={templatedTitle} />}
            {seo.description && (
                <meta property='og:description' content={seo.description} />
            )}
            {seo.image && (
                <React.Fragment>
                    <meta property='og:image' content={seo.image} />
                    <meta property="og:image:type" content="image/jpeg" />
                </React.Fragment>
            )}

            <meta name='twitter:card' content='summary_large_image' />
            {seo.title && <meta name='twitter:title' content={templatedTitle} />}
            {seo.description && (
                <meta name='twitter:description' content={seo.description} />
            )}
            {seo.image && (
                <meta name='twitter:image' content={seo.image} />
            )}
        </Helmet>
    );
};
export default SEO;

SEO.defaultProps = {
    title: null,
    description: null,
    image: null,
    pathname: null,
};