import React from 'react';

const Impressum = () => (
    <div>
        <p>Angaben gemäß § 5 TMG</p>
        <p>Silke Zollondz-Bullinger <br />
            Ebertallee 3<br />
            22607 Hamburg <br />
        </p>
        <p>
            <strong>Vertreten durch: </strong><br/>
            Silke Zollondz-Bullinger<br/>
        </p>
        <p>
            <strong>Kontakt:</strong><br/>
            Telefon: 040-8997643<br/>
            E-Mail: <a href="mailto:dentalinspiration@hamburg.de">dentalinspiration@hamburg.de</a><br/>
        </p>
        <p>
            <strong>Umsatzsteuer-ID:</strong><br/>
            Umsatzsteuer-Identifikationsnummer gemäß §27a Umsatzsteuergesetz: DE160495230<br/>
        </p>
        <p>
            <strong>Aufsichtsbehörde:</strong><br/>
            Handwerkskammer Hamburg<br/>
        </p>
    </div>
);

export default Impressum;