import React from 'react';
import { Link } from 'gatsby';
import classnames from 'classnames/bind';
import styles from './NavItem.module.scss';

const cx = classnames.bind(styles);
class NavItem extends React.Component {
    render () {
        return (
            <span className={cx('navigationItem', { active: this.props.isActive })}>
                {this.props.to ?
                    <Link to={this.props.to}>{this.props.children}</Link>
                    :
                    this.props.children
                }
            </span>
        );
    }
}

export default NavItem;