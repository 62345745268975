import React from 'React';
import FooterLink from '../../components/footer/FooterLink';
import PrivacyPolicy from '../../components/content/PrivacyPolicy';
import Impressum from '../../components/content/Impressum';
import Modal from '../../components/modal/Modal';
import styles from './Footer.module.scss';

export default class Footer extends React.Component {
    constructor (props) {
        super(props);
        this.state = {
            privacyModalVisible: false
        };
    }

    togglePrivacyModal = () =>  {
        if (!this.state.privacyModalVisible) {
            document.body.style['overflow-y'] = 'hidden';
        } else {
            document.body.style['overflow-y'] = '';
        }
        this.setState({
            privacyModalVisible: !this.state.privacyModalVisible
        });
    }

    toggleImpressumModal = () => {
        if (!this.state.impressumModalVisible) {
            document.body.style['overflow-y'] = 'hidden';
        } else {
            document.body.style['overflow-y'] = '';
        }
        this.setState({
            impressumModalVisible: !this.state.impressumModalVisible
        });
    }

    render () {
        return (
            <React.Fragment>
                <div className={styles.footerContainer}>
                    Silke Zollondz &copy;2019&nbsp;|&nbsp;<FooterLink onClick={this.togglePrivacyModal}>Datenschutzerklärung</FooterLink>&nbsp;|
                    &nbsp;<FooterLink onClick={this.toggleImpressumModal}>Impressum</FooterLink>
                </div>
                <Modal 
                    title='Datenschutzerklärung' 
                    isVisible={this.state.privacyModalVisible} 
                    onClickOutside={this.togglePrivacyModal} 
                    onClose={this.togglePrivacyModal}
                >
                    <PrivacyPolicy />
                </Modal>
                <Modal 
                    title='Impressum' 
                    isVisible={this.state.impressumModalVisible} 
                    onClickOutside={this.toggleImpressumModal} 
                    onClose={this.toggleImpressumModal}
                >
                    <Impressum />
                </Modal>
            </React.Fragment>
        );
    }
}