import React from 'react';
import { Link } from 'gatsby';
import styles from './FooterLink.module.scss';

const FooterLink = ({ children, onClick, to }) => {
    return (to ?
        <Link to={to} className={styles.link}>
            {children}
        </Link>
        :
        <a onClick={onClick} className={styles.link}>
            {children}
        </a>
    );
};

export default FooterLink;