import React from 'react';
import classnames from 'classnames/bind';
import styles from './Content.module.scss';

const cx = classnames.bind(styles);
export default class Content extends React.Component {
    constructor (props) {
        super(props);
        this.state = { screenWidth: null };
        this.updateWindowDimensions = this.updateWindowDimensions.bind(this);
    }

    componentDidMount () {
        window.addEventListener('resize', this.updateWindowDimensions());
    }
    
    componentWillUnmount () {
        window.removeEventListener('resize', this.updateWindowDimensions);
    }
    
    updateWindowDimensions () {
        this.setState({ screenWidth: window.innerWidth });
    }

    render () {
        const { children, hideOnResponsive, image, imageSrc, imageFloat, title } = this.props;
        const displayImage = !(this.state.screenWidth <= 768 && hideOnResponsive) && (image || imageSrc);
        const textClasses = cx(styles.textContent, { 
            left: imageFloat === 'right', 
            right: imageFloat === 'left' 
        });
        const Tag = imageSrc ? () => <img src={imageSrc} /> : image;
        const imageClasses = cx (styles.imageContent, styles[imageFloat]);

        return (
            <div className={styles.container}>
                <div className={styles.containerItem}>
                    <div className={textClasses}>
                        <h2 className={styles.heading}>{title}</h2>
                        {children}
                    </div>
                    {displayImage &&
                        <div className={imageClasses}>
                            <Tag />
                        </div>
                    }
                </div>
            </div>
        );
    }
}