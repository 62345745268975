import React from 'react';
import styles from './NavBar.module.scss';

export default class NavBar extends React.Component {
    constructor (props) {
        super(props);
        this.state = {
            scrolled: false
        };
    }

    listenScrollEvent = e => {
        if (window.scrollY === 0) {
            this.setState({
                scrolled: false
            });
        } else {
          this.setState({
                scrolled: true
            });
        }
      }

    componentDidMount() {
        window.addEventListener('scroll', this.listenScrollEvent)
    }

    render () {
        return (
            <div className={styles.navigationBar}>
                {this.props.children}
            </div>
        );
    }
};