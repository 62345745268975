import React from 'react';
import ContactItem from './ContactItem';
import Phone from '../../assets/svg/phone.svg';
import Email from '../../assets/svg/email.svg';
import styles from './Contact.module.scss';

const Contact = () => (
    <div className={styles.container}>
        <ContactItem to='kontakt' image={Phone} />
        <ContactItem href='mailto:dentalinspiration@hamburg.de?Subject=Dentallabor%20Anfrage' image={Email} />
    </div>
);

export default Contact;