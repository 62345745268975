import React from 'react';
import Nav from '../nav/Nav';
import Footer from '../footer/Footer';
import Contact from '../../components/contact/Contact';
import styles from './Master.module.scss';

export default class Master extends React.Component {
    render () {
        return (
            <div className={styles.appMaster}>
                <Nav />
                {this.props.children}
                <div className={styles.divider} />
                <Contact />
                <Footer />
            </div>
        );
    }
}